.App {
  text-align: center;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center; 
  padding: 2rem;
  text-align: center;
  font-size: 2.5rem;
  color: #ff6f00; /* Orange */
  margin-top: -1rem; 
}

.bento-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center; 
  padding: 2rem;
  background: linear-gradient(to right, #ff6f00, #ff4081); /* Lighter Orange to Lighter Pink gradient */
  border: 1px solid #ffffff; /* White */
  border-radius: 10px;
  margin-left: auto; 
  margin-right: auto; 
  max-width: 1000px;
}

.bento-box h2 {
  color: #ffffff; /* White */
}

.bento-box input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  width: 300px;
}

.bento-box button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #ffffff; /* White */
  color: #ff4081; /* Pink */
  cursor: pointer;
}

.loading-box {
  margin-top: 2rem;
}

.results-box {
  margin-top: 2rem;
}

.testimonial-box {
  margin-top: 2rem;
}

.testimonial {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #ffffff; /* Light Orange */
  border-radius: 4px;
}

.cat-gallery {
  margin-top: 2rem;
}

.cat-image {
  margin-bottom: 1rem;
  max-width: 700px;
  border-radius: 10px;
  overflow: hidden;
}

.cat-image img {
  width: 100%;
  height: auto;
}

.top-right-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -2rem; 
}

.login-button,
.signup-button {
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, #ff6f00, #ff4081);
  color: #ffffff; /* Pink */
  cursor: pointer;
}

.popup-bento-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background: linear-gradient(to right, #ffe6c7, #ffbfda); /* Lighter Orange to Lighter Pink gradient */
  border: 1px solid #ffffff; /* White */
  border-radius: 10px;
  z-index: 9999;
}

.popup-bento-box h2 {
  color: #ffffff; /* White */
}

.popup-bento-box form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-bento-box label {
  color: #ffffff; /* White */
  margin-bottom: 0.5rem;
}

.popup-bento-box input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  width: 300px;
}

.popup-bento-box button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #ffffff; /* White */
  color: #ff4081; /* Pink */
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  color: #ffffff; /* White */
  font-size: 1.5rem;
  cursor: pointer;
}
